<template>
  <v-row >
    <v-spacer class="d-none d-lg-block"></v-spacer>
    <v-col cols="12" md="5">
      <v-card  color="white" elevation="0">
        <div class="pa-4 pt-5 mb-7">
          <v-img
            width="170"
            src="../assets/banner.jpeg"
            class="mx-auto"
          ></v-img>
        </div>
      
        <v-row>
          <v-col
            ><v-card color="#F5F5F5" elevation="0" class="pa-4"
              ><v-card-text
                ><span class="text-md-h6 text-body-1  font-weight-bold"
                  >Kota Kemuning (Ephine)</span
                ><br />No 10g, jalan sungai burung AA32/AA, 40460 Shah
                Alam.</v-card-text
              >
              <v-card-text>
                <v-btn class="ma-2" @click="ephineWhatsapp" color="green" small dark>
                  0165312128
                  <v-icon dark right> mdi-whatsapp </v-icon>
                </v-btn>
                <v-btn class="ma-2"  @click="ephineEmenu" color="red" small dark>
                  Order
                  <v-icon dark right> mdi-cart-outline </v-icon>
                </v-btn>
              </v-card-text>
            </v-card></v-col
          >
          <v-col
            ><v-card color="#F5F5F5" elevation="0" class="pa-4">
              <v-card-text>
                <span class="text-md-h6 text-body-1 font-weight-bold"
                  >Ara Damansara (Dino)</span
                ><br />
                D-G-2A, block D, Jalan PJU 1A/3K, Taipan 1, Ara Damansara, 47301
                Petaling Jaya
              </v-card-text>
              <v-card-text>
                <v-btn class="ma-2" @click="dinoWhatsapp" color="green" small dark>
                  01163281781
                  <v-icon dark right> mdi-whatsapp </v-icon>
                </v-btn>
                <v-btn class="ma-2" @click="dinoEmenu" color="red" small dark>
                  Order
                  <v-icon dark right> mdi-cart-outline  </v-icon>
                </v-btn>
              </v-card-text>
            </v-card></v-col
          >
        </v-row>
      </v-card>
    </v-col>
    <v-spacer class="d-none d-lg-block"></v-spacer>
  </v-row>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      // slides: [1, 2, 3, 4, 5],
    };
  },
  computed: {},

  watch: {},

  created() {},

  methods: {
    dinoEmenu(){
      window.location.href = 'https://ad.phangfishery.com/phangfisheryad';
    },

    ephineEmenu(){
      window.location.href = 'https://kk.phangfishery.com/phangfishery';
    },
    ephineWhatsapp(){
      window.location.href = 'https://wa.me/+60165312128';
    },
    dinoWhatsapp(){
      window.location.href = 'https://wa.me/+601163281781';
    }


  },
};
</script>

